import Axios, { AxiosProgressEvent, AxiosResponse, RawAxiosRequestHeaders } from "axios";
import Logger from "../common/Logger";
import AppSettingsService from "./AppSettingsService";

export default class FileApi {
  public static async uploadPageContent(
    file: File,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<string> {
    if (!file) {
      return "";
    }

    try {
      const data = new FormData();
      data.append("file", file);

      const randomFolderName = this.generateRandomFolderName();

      const fileUrl = new URL(AppSettingsService.getECMUrl());
      fileUrl.pathname = `${fileUrl.pathname}/ecm-sportal-${AppSettingsService.getCostcoEnvironment()}/${randomFolderName}/${file.name}`;

      const url = fileUrl.toString();

      const headers: RawAxiosRequestHeaders = {
        "X-FileContentType": file.type,
        "Xm-FileContentType": file.type,
        "costco-tracking-id": `${randomFolderName}`,
        "X-ContentLanguage": "en-US",
        "x-ms-content-type": file.type,
      };

      const response = await Axios.postForm(url, data, {
        withCredentials: true,
        headers: headers,
        onUploadProgress: onUploadProgress
      });

      if (response?.data?.Succeeded) {
        return url;
      }
    } catch (exception: any) {
      Logger.logError(exception);
    }

    return "";
  }

  public static generateRandomFolderName() {
    return Math.random().toString(36).substring(2, 7)
      + Math.random().toString(36).substring(2, 7);
  }

  public static async getECMContentFromURL(imageURL?: string): Promise<string> {
    // This prevents the issue where our production instance has files that were uploaded using our backend service
    // We need to point directly at ECM now, so we'll substitute the two
    const correctedURL = imageURL?.replace(`${AppSettingsService.getBaseUrl()}/file/static`, AppSettingsService.getECMUrl());

    if (correctedURL?.startsWith(AppSettingsService.getECMUrl())) {
      return Axios.get(correctedURL, {
        withCredentials: true,
        responseType: "arraybuffer",
      })
        .then((response: AxiosResponse) => {
          const fileNameStart = correctedURL?.lastIndexOf("/");
          if (fileNameStart >= 0) {
            const fileName = correctedURL?.substring(fileNameStart + 1);
            const file = new File([response.data], fileName, {
              type: response.headers.getContentType?.toString(),
            });

            return URL.createObjectURL(file);
          }
          else {
            return URL.createObjectURL(new Blob([response.data], {
              type: response.headers.getContentType?.toString(),
            }));
          }


        })
        .catch((reason) => {
          Logger.logError("Failed to fetch image for news content", reason);
          return "";
        });
    }

    return correctedURL ?? "";
  }
}
