import { ReactElement, useEffect, useState } from "react";
import FileApi from "../../api/FileApi";
import ImageErrorFunction from "../../util/ImageErrorFunction";
import CostcoBlueLoader from "./LoadingIndicators";
import fallback from "../../images/fallback.png";

/**
 * Loads an image, if the image is from ECM, this will request it in a secure context.
 * While loading, it will show as a costco blue loading spinner
 * @param props props with image URL
 * @constructor
 */
export default function ECMImage(props: {
  imageUrl?: string;
  className?: string;
  altText?: string;
}) {
  const [image, setImage] = useState<ReactElement>(CostcoBlueLoader);

  useEffect(() => {
    // if (props.imageUrl) {
    FileApi.getECMContentFromURL(props.imageUrl)
      .then((imageSrc) => {
        setImage(() => (
          <img
            className={props.className}
            alt={props.altText}
            src={imageSrc as string}
            onError={ImageErrorFunction}
          />
        ));
      })
      .catch(() => {
        setImage(() => (
          <img
            className={props.className}
            alt={props.altText}
            src={fallback}
            onError={ImageErrorFunction}
          />
        ));
      });
    // }
  }, [props.className, props.imageUrl, props.altText]);

  return image;
}
