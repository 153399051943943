import { useState } from "react";
import ItemData from "../../api/interface/cdh/ItemData";
import expandMoreSVG from "../../images/svg/expand_more.svg";

export interface ItemInfoProps {
  data: ItemData;
}

const ItemInfo = (props: ItemInfoProps) => {
  const { data } = props;
  const { description, secondaryDescription, id, bbaId, itemThumbnail } = data;
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const itemTitle: string = description ?? "Unknown Item";
  const itemSubtitle = secondaryDescription ?? "Unknown";
  const itemId = id;

  const apNumber: string = String(bbaId?.apNumber ?? "-");
  const bbaNumber: string = String(bbaId?.bbaNumber ?? "-");
  const renderData = () => {
    const getFormattedData = () => {
      const newData: ItemData | any = data;
      delete newData.id;
      delete newData._id;
      const renderRow = (obj: ItemData | undefined) => {
        const rowArr = [];
        if (obj && Object.keys(obj).length > 0) {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in obj) {
            const name = i.toString();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const value = obj[name];
            rowArr.push({ name, value });
          }
        }
        return rowArr.map((row) => (
          <tr key="detailed-row" className="h-auto font-semibold text-gray">
            <td
              key={row.name}
              className="border-2 border-white border-spacing-2"
            >
              {row.name}
            </td>
            <td
              key={row.value}
              className="border-2 border-white border-spacing-5"
            >
              {typeof row.value === "string" ? (
                <i>{row.value}</i>
              ) : (
                renderRow(row.value)
              )}
            </td>
          </tr>
        ));
      };

      return (
        <table className="table table-striped h-auto table-auto w-[100px]">
          <tbody>{renderRow(newData)}</tbody>
        </table>
      );
    };
    const handleClick = () => {
      setShowDetails(!showDetails);
    };
    return (
      <div className="bg-white">
        <button
          type="button"
          className="text-sm font-semibold"
          onClick={handleClick}
        >
          <label className="inline-block">Show more details </label>
          <img
            className="inline-block mx-2 align-middle h-[22px]"
            src={expandMoreSVG as string}
            alt="expand for more item details"
          />
        </button>
        {showDetails && (
          <div className="overflow-y-auto h-auto text-xs pl-10 mt-2 ml-5 bg-gray-500 bg-opacity-10">
            <div>{getFormattedData()}</div>
          </div>
        )}
      </div>
    );
  };

  const vendorName = bbaId?.name ?? "Unknown Vendor";

  return (
    <div className="flex mt-4 h-auto w-auto">
      {itemThumbnail && (
        <img
          alt="Item thumbnail"
          className="h-48 object-contain mr-12"
          src={itemThumbnail as string}
        />
      )}
      <div className="pt-2 text-gray-700">
        <div className="font-semibold text-xl">{itemTitle}</div>
        <div className="text-base font-light">{itemSubtitle}</div>
        <div className="text-sm pt-4 grid grid-cols-2 gap-x-8">
          <span>Item ID</span>
          <span>{itemId}</span>
          <span>AP Vendor Number</span>
          <span>{apNumber}</span>
          <span>Vendor Name</span>
          <span>{vendorName}</span>
          <span>BBA Vendor Number</span>
          <span>{bbaNumber}</span>
        </div>
        {renderData()}
      </div>
    </div>
  );
};

export default ItemInfo;
