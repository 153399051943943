import { ReactElement } from "react";

import PageContent from "../../api/interface/PageContent";
import ECMContentLink from "../common/ECMContentLink";
import ECMImage from "../common/ECMImage";

type HeroItemProps = {
  id: number;
  item: PageContent;
};

export default function HeroContent(props: HeroItemProps): ReactElement {
  const render = () => {
    const { id, item } = props;
    if (!item) {
      return <div />;
    }
    const coreDisplayClasses =
      "flex flex-none align-center h-96 w-full justify-center";

    return (
      <ECMContentLink
        id={`hero-item-${id}`}
        contentUrl={item.link}
        className={coreDisplayClasses}
      >
        <div className="h-full w-1/2 md:w-1/3 bg-green-700 px-2 text-white flex flex-col justify-center">
          <div className="text-xl md:text-3xl text-right">{item.title}</div>
          <div className="mt-2 text-sm text-right italic">{item.subtitle}</div>
        </div>
        <ECMImage
          imageUrl={item.image}
          altText="News article"
          className={`h-full w-1/2 md:w-2/3 object-cover object-center ${item.styles?.image ?? ""}`}
        />
      </ECMContentLink>
    );
  };

  return render();
}
