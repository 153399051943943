import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Axios from "axios";
import { v4 } from "uuid";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { Tooltip } from "react-tooltip";
import Contact from "../../../api/interface/Contact";
import { DefaultContactsTableColumns } from "./VendorDetailsPageColumnDefinitions";
import VendorInfo from "../../../api/interface/cdh/VendorInfo";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_SELECTOR,
} from "./ConstantsGlossary";
import AppSettingsService from "../../../api/AppSettingsService";
import { RetrieveUserResponse } from "../../../api/interface/vam/RetrieveUserResponse";
import useAuth from "../../../hooks/useAuth";
import convertVendorUserToContact from "../../../api/interface/vam/UserResponseConversion";
import Logger from "../../../common/Logger";
import VAMContactActionCellRenderer from "./CellRenderer/VAMContactActionCellRenderer";
import svgExclamation from "../../../images/svg/exclamation.svg";

interface VAMContactsTableProps {
  vendor?: VendorInfo;
}

const VAMContactsTable = (props: VAMContactsTableProps) => {
  const { vendor } = props;
  const [contacts, setContacts] = useState<Contact[]>();
  const { user } = useAuth();

  useEffect(() => {
    // Preparing query parameters for pagination, sorting, and filtering
    const entitlementFilters = ["VSP-READ", "VSP-ESG_Lead", "EAM-VendorAdmin"];

    const axiosConfigs = entitlementFilters.map((filter) => {
      const queryParams = {
        vendorId: vendor!.sapNumber,
        entitlementFilter: filter,
      };
      return {
        params: queryParams,
        withCredentials: true,
        headers: {
          "Costco-Sending-Application": "VSP-SPA",
          "Costco-Tracking-Id": v4(),
          "Costco-User-Id": user?.sAMAccountName ?? "unknown",
          "Costco-X-Authorization": "IdPPingFed",
        },
      };
    });

    const axiosRequests = axiosConfigs.map((axiosConfig) => {
      // vendors/users?vendorId=7717600&entitlementFilter=<SingleFilter>
      const vamUrl = `${AppSettingsService.getVAMUrl()}/vendors/users`;
      return Axios.get<RetrieveUserResponse>(vamUrl, axiosConfig);
    });

    Promise.allSettled(axiosRequests)
      .then((responses) => {
        const userIdDedupSet = new Set<string>();

        const userContacts = responses
          // Make sure the responses were fulfilled
          .filter((response) => response.status === "fulfilled")
          // Get the data from the response
          .map((fulfilledResponse) => fulfilledResponse.value.data)
          // And extract the list of vendor users
          .map((retrieveUserResponse) => retrieveUserResponse.vendorUser)
          // Take each array and flatten it from VendorUser[][] to VendorUser[]
          .flat()
          // Use the set, if the user id has not been added to the set previously, add it
          // Return true if the user was not added to the set up until this point
          .filter((vendorUser) => {
            const userWasAdded = userIdDedupSet.has(vendorUser.profile.id);
            if (!userWasAdded) {
              userIdDedupSet.add(vendorUser.profile.id);
            }
            return !userWasAdded;
          })
          // Convert it to a contact from the vendor user type
          .map((vendorUser) => convertVendorUserToContact(vendorUser));

        setContacts(userContacts);
      })
      .catch((error) => {
        Logger.logError("Error while fetching data from server:", error);
        setContacts([]);
      });
  }, [user, vendor]);
  const VAMContactsTableColumnsWithActions: (
    | ColDef<Contact>
    | ColGroupDef<Contact>
  )[] = DefaultContactsTableColumns.concat([
    {
      headerName: "User Type",
      sortable: true,
      field: "sustainabilityRole",
      flex: 1.5,
      headerClass: "contactTableHeader",
    },
    {
      headerName: "Action",
      flex: 1,
      sortable: false,
      headerClass: "contactTableHeader",
      field: "email",
      cellRenderer: VAMContactActionCellRenderer,
    },
  ]);
  return vendor ? (
    <>
      <div className="mb-4 min-w-full flex flex-row">
        <div className="text-lg font-black no-wrap">
          Registered Portal Users
        </div>
        <img
          id="vendor-profile-registered-portal-users-tooltip"
          src={svgExclamation as string}
          className="inline-block pl-4 text-gray-dark"
          alt="exclamation icon"
        />
        <Tooltip
          anchorSelect="#vendor-profile-registered-portal-users-tooltip"
          place="right"
          className="text-sm"
        >
          These users are registered to access the Vendor Sustainability Portal
        </Tooltip>
      </div>
      <AgGridReact<Contact>
        domLayout="autoHeight"
        rowData={contacts ?? []}
        columnDefs={VAMContactsTableColumnsWithActions}
        infiniteInitialRowCount={DEFAULT_PAGE_SIZE}
        loading={contacts === undefined}
        pagination
        paginationPageSize={DEFAULT_PAGE_SIZE}
        paginationPageSizeSelector={DEFAULT_PAGE_SIZE_SELECTOR}
        rowSelection="single"
        overlayNoRowsTemplate="No Users Registered"
        columnHoverHighlight
        className="ag-theme-quartz h-96 mb-4"
      />
    </>
  ) : (
    <div className="snap-center flex font-normal h-96">
      <p>No contacts found.</p>
    </div>
  );
};
export default VAMContactsTable;
