import { ReactElement } from "react";
import StyledInputProps from "../interface/StyledInputProps";
import LabeledInput from "./LabeledInput";

export default function StyledInput<T>(props: StyledInputProps): ReactElement {
  const render = () => {
    return (
      <div className="relative py-2">
        <LabeledInput<T> config={props} />
      </div>
    );
  };

  return render();
}
