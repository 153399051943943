const NotFoundPage = () => (
  <div className="pt-12 bg-gray-100 text-gray-800 h-screen justify-center text-center">
    <div className="mx-auto">
      <div className="tracking-widest mt-4">
        <span className="text-6xl block">
          <span>YIKES!</span>
        </span>
        <span className="ext-xl">This page doesn&apos;t exist!</span>
      </div>
      <div className="mt-6">
        <a
          href="/"
          className="text-gray-700 font-mono text-xl bg-gray-200 p-3 rounded-md hover:shadow-md"
        >
          Go home
        </a>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
