import AppSettingsService from "../api/AppSettingsService";

const ErrorPage = (props: {
  title: string;
  message: string;
  details?: string;
}) => {
  const { title, message, details } = props;

  const apiUrl: string = AppSettingsService.getBaseUrl();
  const isNonProd: boolean =
    apiUrl.includes("costco.internal") ||
    apiUrl.includes("localhost") ||
    apiUrl.includes("np.ct-costco.com");

  return (
    <div className="pt-12 bg-gray-100 text-gray-800 h-screen justify-center text-center">
      <div className="mx-auto max-w-4xl">
        <div className="tracking-widest mt-4">
          <span className="text-6xl block">
            <span>YIKES!</span>
          </span>
          <span className="mt-2 text-xl">{title}</span>
        </div>
        <div className="text-gray-700 font-mono text-base pt-6 rounded-md">
          {message}
        </div>
        {details !== undefined && (
          <div className="mt-2 text-gray-700 font-mono text-xs rounded-md">
            {details}
          </div>
        )}
        {/* TODO: ONLY RENDER THIS IN NON-PROD */}
        {isNonProd && (
          <div className="mt-2 text-gray-700 font-mono text-xs rounded-md">
            If this is your first time connecting to the Sustainability Portal,{" "}
            <a
              className="mt-2 text-xs rounded-md text-blue underline "
              target="_window"
              href={`${apiUrl}/healthcheck`}
            >
              click here
            </a>{" "}
            to trust our server, then reload the page.
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
