import {
  IDatasource,
  IGetRowsParams,
  SortModelItem,
} from "ag-grid-community";
import Axios from "axios";
import { PaginatedResponse } from "../interface/PaginatedResponse";
import VendorInfo from "../interface/cdh/VendorInfo";

class VendorServerSideDatasource implements IDatasource {
  private _searchText: string = "";
  private _vendorType: string = "ap";

  constructor(vendorType?: string) {
    this._vendorType = vendorType ?? "bba";
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  set vendorType(value: string) {
    this._vendorType = value;
  }

  // Implementation of the getRows method from IServerSideDatasource
  getRows(params: IGetRowsParams) {
    // Extract necessary parameters from params
    const { startRow, endRow, sortModel } = params;

    // Preparing query parameters for pagination, sorting, and filtering
    const queryParams = {
      startRow: startRow,
      endRow: endRow,
      vendorType: this._vendorType,
      ...this.getQueryParams(),
      ...this.parseSortModel(sortModel),
    };

    // Make an axios call to fetch data
    Axios.get<PaginatedResponse<VendorInfo>>("/vendors", {
      params: queryParams,
    })
      .then((response) => {
        if (response.data) {
          const { items, size } = response.data;
          params.successCallback(items, size);
        }
        else {
          params.successCallback([]);
        }
      })
      .catch((error) => {
        console.error("Error while fetching data from server:", error);
        params.failCallback();
      });
  }

  getQueryParams() {
    if (this._searchText) {
      return { search: this._searchText };
    }

    return {};
  }

  parseSortModel(sortModel: SortModelItem[]) {
    if (sortModel && sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      // If sort is ascending, no minus necessary before sort column
      const sortDir = sort === "asc" ? "" : "-";
      return { sortBy: `${sortDir}${colId}` };
    }
    return {};
  }
}

export default VendorServerSideDatasource;
