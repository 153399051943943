import {
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  GridReadyEvent,
  IDatasource,
  RowClickedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_SELECTOR,
} from "../../pages/VendorDetailsPage/VendorDetailsProperties/ConstantsGlossary";
import CostcoBlueLoader from "../common/LoadingIndicators";

const DefaultGrid = <T,>({
  columnDefs,
  datasource,
  onSortChanged,
  onRowClicked,
  rowId,
  tableType,
  onGridReady,
}: {
  rowId: GetRowIdFunc<T>;
  datasource: IDatasource;
  columnDefs: (ColDef<T> | ColGroupDef<T>)[];
  onSortChanged?: (event: SortChangedEvent<T>) => void;
  onRowClicked?: (event: RowClickedEvent<T>) => void;
  onGridReady?: (event: GridReadyEvent<T>) => void;
  tableType?: string;
}) => {
  const ref = useRef(null);
  const loadingOverlay = () => <CostcoBlueLoader />;
  const noRowOverlay = (table?: string) => <div>No {table} result found.</div>;
  return (
    <AgGridReact<T>
      getRowId={rowId}
      rowModelType="infinite"
      datasource={datasource}
      domLayout="autoHeight"
      columnDefs={columnDefs}
      cacheBlockSize={20}
      infiniteInitialRowCount={DEFAULT_PAGE_SIZE}
      defaultCsvExportParams={{}}
      pagination
      paginationPageSize={DEFAULT_PAGE_SIZE}
      paginationPageSizeSelector={DEFAULT_PAGE_SIZE_SELECTOR}
      valueCache
      rowSelection="single"
      onSortChanged={onSortChanged}
      onRowClicked={onRowClicked}
      columnHoverHighlight
      className="ag-theme-quartz h-96"
      loadingOverlayComponent={loadingOverlay}
      noRowsOverlayComponent={noRowOverlay(tableType)}
      onGridReady={onGridReady}
      ref={ref}
    />
  );
};

export default DefaultGrid;
