import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { toast, ToastContainer } from "react-toastify";
import VendorInfo from "../../api/interface/cdh/VendorInfo";
import Contact from "../../api/interface/Contact";
import ItemsTable from "./VendorDetailsProperties/ItemsTable";
import BBATable from "./VendorDetailsProperties/BBATable";
import ItemData from "../../api/interface/cdh/ItemData";
import ContactsTable from "./VendorDetailsProperties/ContactsTable";
import VAMContactsTable from "./VendorDetailsProperties/VAMContactsTable";
import { capitalizeFirstLetter } from "../../util/Helper";

export interface VendorViewProps {
  vendor: VendorInfo;
  itemSelection: (itemData?: ItemData) => void;
  contactSelection: (contact?: Contact) => void;
  section?: string;
}

const VendorDetailsController = (props: VendorViewProps): ReactElement => {
  const { vendor, contactSelection, section, itemSelection } = props;
  const navigate = useNavigate();
  const { sapNumber, apNumber, bbaNumber, name } = vendor;

  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  const renderNavigation = () => {
    const vendorQuery = localStorage.getItem("VendorSearchQuery") || "";
    const navigateToVendorSearchPage = () =>
      navigate({
        pathname: "/vendors/",
        search: vendorQuery,
      });
    const navigateToApVendorPage = () =>
      navigate({
        pathname: `/vendor/${sapNumber}/contacts`,
      });

    const sectionName =
      section === "bba"
        ? "BBA Vendors"
        : `${/.*(?=s)/.exec(capitalizeFirstLetter(section))}s`;

    return (
      <div className="mt-4 w-full text-sm text-gray-900">
        <button
          type="button"
          className="inline-flex hover:underline hover:text-blue hover:cursor-pointer"
          onClick={navigateToVendorSearchPage}
        >
          <label>Vendor Rolodex</label>
        </button>
        <div className="inline-flex justify-between mx-2">
          <label> / </label>
        </div>
        <button
          type="button"
          className="inline-flex hover:underline hover:text-blue"
          onClick={navigateToApVendorPage}
        >
          <label>{`${apNumber} - ${name}`}</label>
        </button>
        <div className="inline-flex justify-between mx-2">
          <label> / </label>
        </div>
        {bbaNumber && (
          <>
            <button type="button" className="inline-flex ml-1">
              <label>{`BBA - ${bbaNumber}`}</label>
            </button>
            <div className="inline-flex justify-between mx-2">
              <label> / </label>
            </div>
          </>
        )}
        <button type="button" className="inline-flex ml-1">
          <label>{sectionName}</label>
        </button>
      </div>
    );
  };
  const vendorHeader = () => {
    return (
      <div className="flex mt-6">
        <div className="pt-2 hover:cursor-pointer">
          <div className="text-light text-xl text-blue-700">{name}</div>
          <div className="text-md pt-4 text-gray-700">
            <div>
              <span>AP Number:</span>
              <span className="ml-1">{apNumber}</span>
            </div>
            <div>
              <span>BBA Number:</span>
              <span className="ml-1">{bbaNumber}</span>
            </div>
            {/* <div> */}
            {/*  <span>Sustainability Lead</span> */}
            {/*  <span className="pl-8 ml-0.5">Unknown</span> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  };

  const switchSection = (sectionId: string) => {
    const pathSuffix = bbaNumber ? `/${bbaNumber}` : "";
    const pathId = `${sapNumber}${pathSuffix}`;
    const pathname = `/vendor/${pathId}/${sectionId}`;
    let currentSearch: string = "";
    if (sectionId === "items") {
      currentSearch = localStorage.getItem("ItemSearchQuery") || "";
    }
    if (sectionId === "bba") {
      currentSearch = localStorage.getItem("ChildSearchQuery") || "";
    }
    if (sectionId === "contacts" || !sectionId) {
      currentSearch = localStorage.getItem("VendorSearchQuery") || "";
    }
    navigate({
      pathname,
      search: currentSearch,
    });
  };

  /** Renders the different headers for the details sections. */
  const renderSectionHeaders = () => {
    const sections = [
      { value: "contacts", name: "Contacts" },
      { value: "items", name: "Items" },
    ];
    if (!bbaNumber) {
      sections.push({ value: "bba", name: "BBA Vendors" });
    }

    const selectedSection = section ?? "items";
    return (
      <div className="w-full mt-6 flex">
        {sections.map((s) => {
          const isSelected = s.value === selectedSection;
          const classList = [
            "text-center flex-grow text-lg pb-4 border-b-[3px] text-gray-900 hover:text-black hover:border-blue-500 cursor-pointer",
          ];
          classList.push(
            isSelected ? "font-bold border-CostcoAction" : "border-gray-400",
          );
          return (
            <button
              type="button"
              onClick={() => switchSection(s.value)}
              key={`${s.value}-section`}
              data-value={s.value}
              className={classList.join(" ")}
            >
              {s.name}
            </button>
          );
        })}
      </div>
    );
  };

  /** Navigates to a page for viewing BBA details. */
  const selectVendor = (vendorInfo: VendorInfo) => {
    const pathSuffix = vendorInfo.bbaNumber ? `/${vendorInfo.bbaNumber}` : "";
    const pathId = `${vendorInfo.sapNumber}${pathSuffix}`;
    navigate({
      pathname: `/vendor/${pathId}/contacts`,
      search: "",
    });
  };

  const renderItems = () => {
    return <ItemsTable vendor={vendor} itemSelection={itemSelection} />;
  };
  const renderContactsTable = () => {
    return (
      <>
        <VAMContactsTable vendor={vendor} />
        <ContactsTable vendor={vendor} contactSelection={contactSelection} />
      </>
    );
  };
  const renderBBATable = () => {
    return <BBATable vendor={vendor} selectVendor={selectVendor} />;
  };

  return (
    <>
      {renderNavigation()}
      {vendorHeader()}
      <div className="mt-8 border-b-[1px] border-gray-400" />
      <div>
        <div>
          {renderSectionHeaders()}
          <div className="mt-8" />
          <div>
            {section === "items" && renderItems()}
            {section === "contacts" && renderContactsTable()}
            {section === "bba" && renderBBATable()}
          </div>
          <ToastContainer position="bottom-right" autoClose={1000} />
        </div>
      </div>
    </>
  );
};

export default VendorDetailsController;
