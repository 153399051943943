import svgLoading from "../images/svg/loading.svg";

const LoadingPage = () => (
  <div>
    <div className="z-10 h-full w-full place-content-center">
      <div className="mx-auto mt-80 object-center w-16 h-16">
        <img src={svgLoading} alt="loading..." />
      </div>
      <div className="pt-4 text-lg text-center">Loading...</div>
    </div>
  </div>
);

export default LoadingPage;
