import {
  ChangeEvent,
  DragEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 } from "uuid";
import FileInfo from "../../util/FileInfo";
import FileIconUtil from "../../util/FileIconUtil";

export interface FilePickerProps {
  selectionChanged: (f: File | undefined, link: string | undefined) => void;
  useImageMode: boolean;
}

const acceptedTypes = [
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/gif",
  "text/calendar",
  "image/jpeg",
  "application/json",
  "image/png",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "image/svg+xml",
  "image/tiff",
  "text/plain",
  "image/webp",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".csv",
  ".doc",
  ".docx",
  ".gif",
  ".ics",
  ".jpeg, .jpg",
  ".json",
  ".png",
  ".pdf",
  ".ppt",
  ".pptx",
  ".svg",
  ".tif, .tiff",
  ".txt",
  ".webp",
  ".xls",
  ".xlsx",
];

const FilePicker = (props: FilePickerProps): ReactElement => {
  const { selectionChanged, useImageMode } = props;
  const [file, setFile] = useState<File>();
  const [fileInfo, setFileInfo] = useState<FileInfo>(new FileInfo(file));
  const [linkUrl, setLinkUrl] = useState<string>();
  const dropzoneFileRef = useRef<HTMLInputElement>(null);
  const id = v4();

  useEffect(() => {
    setFileInfo(new FileInfo(file));
    selectionChanged(file, linkUrl);
  }, [selectionChanged, file, linkUrl]);

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (dropzoneFileRef && dropzoneFileRef.current && droppedFiles) {
      setLinkUrl("");
      setFile(droppedFiles[0]);
    }
  };

  const fileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setLinkUrl("");
    } else {
      setFile(undefined);
    }
  };

  const renderClearButton = () => (
    <button
      type="button"
      className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 cursor-pointer"
      onClick={() => {
        setFile(undefined);
      }}
    >
      <span className="material-icons-outlined">close</span>
    </button>
  );

  const filePicker = () => {
    if (useImageMode) {
      return (
        <input
          id={`filePicker-${id}`}
          ref={dropzoneFileRef}
          type="file"
          className="hidden"
          onChange={fileChanged}
          accept="image/*"
        />
      );
    }
    return (
      <input
        id={`filePicker-${id}`}
        ref={dropzoneFileRef}
        type="file"
        className="hidden"
        onChange={fileChanged}
        accept={acceptedTypes.join(",")}
      />
    );
  };

  const showPreview = () => {
    if (useImageMode) {
      return (
        <>
          <div className="flex-grow px-2 max-h-48 max-w-full">
            <img
              className="object-contain max-h-48 max-w-full"
              src={file ? URL.createObjectURL(file) : ""}
              alt="thumnail"
            />
          </div>
          <p className="mt-2 mb-0.5 text-sm text-gray-700">
            <span className="font-semibold">{fileInfo.getName()}</span>
          </p>
        </>
      );
    }

    return (
      <>
        <div className="flex-none px-2 h-16 w-16">
          <FileIconUtil extension={fileInfo.extension as string} />
        </div>
        <p className="mt-2 mb-0.5 text-sm text-gray-700">
          <span className="font-semibold">{fileInfo.getName()}</span>
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-600">
          {fileInfo.getFileSize()}
        </p>
      </>
    );
  };

  const render = () => {
    const fileSelected = file !== undefined;
    const placeholderText: string = useImageMode
      ? "Clear the selected image to input a url."
      : "Clear the selected file to input a url.";
    return (
      <>
        <div className="flex w-full items-center justify-center relative">
          {fileSelected && renderClearButton()}
          <label
            htmlFor={`filePicker-${id}`}
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-200 bg-gray-50 hover:bg-gray-100"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              {!fileSelected && (
                <>
                  <span className="material-icons-outlined text-4xl text-gray-600">
                    {useImageMode ? "add_photo_alternate" : "cloud_upload"}
                  </span>
                  <p className="mb-2 text-sm text-gray-600">
                    <span className="font-semibold">
                      Click to upload {useImageMode ? "an image" : ""}
                    </span>{" "}
                    or drag and drop
                  </p>
                  <p className="text-xs text-gray-600">Max file size 100 MB</p>
                </>
              )}
              {fileSelected && showPreview()}
            </div>
            {filePicker()}
          </label>
        </div>

        <div className="mx-auto my-4 w-full text-center text-gray-700">OR</div>

        <div className="group relative">
          <label
            className={[
              "absolute rounded-bl-lg rounded-tl-lg pl-4 pr-3  py-1 text-base font-medium",
              "border border-gray-400 bg-gray-150 text-gray-600",
              "group-focus-within:bg-gray-500/20 group-focus-within:text-gray-800 group-hover:bg-gray-400/20 group-hover:text-gray-800",
            ].join(" ")}
            htmlFor={`url-input-${id}`}
          >
            {useImageMode ? "Link to image" : "Link to url"}
          </label>
          <input
            id={`url-input-${id}`}
            type="url"
            className={[
              "block w-full cursor-pointer rounded-lg px-2 py-1",
              "text-base focus:outline-none",
              "disabled:italic disabled:font-light",
              "border border-gray-400 text-gray-900 disabled:placeholder-gray-600",
              useImageMode ? "pl-36" : "pl-28",
            ].join(" ")}
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            disabled={file !== undefined}
            placeholder={file === undefined ? "" : placeholderText}
          />
        </div>
      </>
    );
  };

  return render();
};

export default FilePicker;
