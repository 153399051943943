import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import ItemData from "../../../api/interface/cdh/ItemData";
import VendorInfo from "../../../api/interface/cdh/VendorInfo";
import Contact from "../../../api/interface/Contact";

/* This file controls all the Column Definitions/Header on Vendor Details Page */

/* Start of Columns Definitions for Items Ag-grid Table on Vendor Profile Page */
const countryFormatter = (params: ValueFormatterParams) => {
  if (!params.value) {
    return "";
  }
  switch (params.value) {
    case 1:
      return "US";
    case 4:
      return "CN";

    default:
      return "Others";
  }
};
const organicFormatter = (params: ValueFormatterParams) => {
  if (!params.value) {
    return "";
  }

  return params.value.toLowerCase() === "y" ? "YES" : "NO";
};
const defaultItemTableColumns: (ColDef<ItemData> | ColGroupDef<ItemData>)[] = [
  {
    headerName: "Descriptions",
    headerClass: "itemTableHeader",
    children: [
      {
        field: "description",
        flex: 1,
        headerClass: "itemTableHeader",
      },
      {
        columnGroupShow: "open",
        headerName: "Secondary Description",
        field: "secondaryDescription",
        flex: 1,
        headerClass: "itemTableHeader",
      },
    ],
  },
  {
    headerName: "Categories",
    headerClass: "itemTableHeader",
    children: [
      {
        headerName: "Category 1",
        field: "department.category1.description",
        flex: 0.5,
        headerClass: "itemTableHeader",
      },
      {
        columnGroupShow: "open",
        headerName: "Category 2",
        field: "department.category2.description",
        flex: 0.5,
        headerClass: "itemTableHeader",
      },
      {
        columnGroupShow: "open",
        headerName: "Category 3",
        field: "department.category3.description",
        flex: 0.5,
        headerClass: "itemTableHeader",
      },
    ],
  },
  {
    headerName: "Organic",
    field: "organic",
    flex: 0.5,
    valueFormatter: organicFormatter,
    headerClass: "itemTableHeader",
  },
];

const CountryColDef: ColDef<ItemData> = {
  headerName: "Country / Region",
  field: "country",
  flex: 0.5,
  valueFormatter: countryFormatter,
  headerClass: "itemTableHeader",
};
const ItemNumberColDef: ColDef<ItemData> = {
  headerName: "Item Number",
  field: "id",
  flex: 0.5,
  //pinned: "left",
  headerClass: "itemTableHeader",
  sortable: true,
  sortingOrder: ["asc", "desc"],
  comparator: (valueA, valueB) => {
    console.log("here", valueA, valueB);
    return valueA - valueB;
  },
};
export const BBAVendorItemTableColumns: (
  | ColDef<ItemData>
  | ColGroupDef<ItemData>
)[] = [CountryColDef, ItemNumberColDef, ...defaultItemTableColumns];

export const APVendorItemTableColumns: (
  | ColDef<ItemData>
  | ColGroupDef<ItemData>
)[] = [
  CountryColDef,
  ItemNumberColDef,
  {
    headerName: "BBA Vendor ID",
    field: "bbaId.bbaNumber",
    flex: 0.5,
    headerClass: "itemTableHeader",
  },
  ...defaultItemTableColumns,
];
/* End of Columns Definitions for Items Ag-grid Table on Vendor Profile Page */

/* Start of Columns Definitions for Contacts Ag-grid Table on Vendor Profile Page */
export const DefaultContactsTableColumns: (
  | ColDef<Contact>
  | ColGroupDef<Contact>
)[] = [
  {
    headerName: "First Name",
    sortable: true,
    field: "firstName",
    flex: 1,
    headerClass: "contactTableHeader",
  },
  {
    headerName: "Last Name",
    sortable: true,
    field: "lastName",
    flex: 1,
    headerClass: "contactTableHeader",
  },
  {
    headerName: "Email",
    sortable: true,
    field: "email",
    flex: 1,
    headerClass: "contactTableHeader",
  },
  {
    headerName: "Phone",
    sortable: false,
    field: "phone",
    flex: 1,
    headerClass: "contactTableHeader",
  },
];

/* End of Columns Definitions for Contacts Ag-grid Table on Vendor Profile Page */

/* Start of Columns Definitions for BBA/Child Vendor Ag-grid Table on Vendor Profile Page */
export const DefaultChildVendorColumns: (
  | ColDef<VendorInfo>
  | ColGroupDef<VendorInfo>
)[] = [
  {
    headerName: "BBA Number",
    sortable: true,
    field: "bbaNumber",
    flex: 1,
  },
  {
    headerName: "Name",
    sortable: true,
    field: "name",
    flex: 3,
  },
];
/* End of Columns Definitions for BBA/Child Vendor Ag-grid Table on Vendor Profile Page */

