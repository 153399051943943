import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemData from "../../api/interface/cdh/ItemData";
import VendorApi from "../../api/VendorApi";
import VendorInfo from "../../api/interface/cdh/VendorInfo";
import VendorDetailsController from "./VendorDetailsController";
import ContactSection from "../../components/sections/ContactSection";
import Contact from "../../api/interface/Contact";
import ItemDetailsSection from "../../components/sections/ItemDetailsSection";

const VendorDetailsPage = (): ReactElement => {
  const params = useParams();
  const navigate = useNavigate();

  const [showContact, setShowContact] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<Contact>();

  const [showItemDetails, setShowItemDetails] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemData>();

  const [vendorData, setVendorData] = useState<VendorInfo>();

  useEffect(() => {
    const { sapNumber, bbaNumber } = params;
    VendorApi.getVendor(sapNumber!, bbaNumber).then((data) => {
      if (data) {
        setVendorData(data);
      }
    });
  }, [params]);

  const close = async () => {
    localStorage.removeItem("ItemSearchQuery");
    localStorage.removeItem("ChildSearchQuery");
    const searchQuery = localStorage.getItem("VendorSearchQuery") || "";
    navigate({ pathname: `/vendors`, search: searchQuery });
  };

  const itemSelection = (item?: ItemData) => {
    setSelectedItem(item);
    setShowItemDetails(item !== undefined);
  };

  const contactSelection = (contact?: Contact) => {
    setSelectedContact(contact);
    setShowContact(true);
  };

  const onItemClose = () => {
    setShowItemDetails(false);
  };
  const onContactClose = () => {
    setShowContact(false);
    setSelectedContact({});
  };

  const render = () => {
    return (
      <div className="flex-grow self-center w-full max-w-8xl px-4 md:px-16 box-border">
        <div id="main-wrapper" className="w-full text-left relative">
          <div
            role="presentation"
            className="relative w-full h-full bg-white overflow-x-hidden"
          >
            <button
              type="button"
              className="absolute top-8 right-0 text-gray-500 hover:text-gray-800 cursor-pointer"
              onClick={close}
            >
              <span className="material-icons-outlined text-gray-950">
                close
              </span>
            </button>
            {vendorData && (
              <VendorDetailsController
                vendor={vendorData}
                section={params.section}
                itemSelection={itemSelection}
                contactSelection={contactSelection}
              />
            )}
          </div>
        </div>
        <ContactSection
          sapNumber={vendorData?.sapNumber ?? 0}
          visible={showContact}
          contact={selectedContact}
          closeFunction={onContactClose}
        />
        <ItemDetailsSection
          visible={showItemDetails}
          item={selectedItem}
          closeFunction={onItemClose}
        />
      </div>
    );
  };

  return render();
};

export default VendorDetailsPage;
