import React, { ReactElement, useState } from "react";

import svgLogo from "../images/svg/logo.svg";
import PermissionService from "../permissions/PermissionService";
import AuthApi from "../api/AuthApi";
import useAuth from "../hooks/useAuth";

export default function Header(): ReactElement {
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
  const [showNotificationMenu, setShowNotificationMenu] =
    useState<boolean>(false);

  const shouldRenderAdditionalButtons: boolean = false;
  const { user } = useAuth();

  const onProfileSelected = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
  };

  window.addEventListener("click", () => {
    if (showProfileMenu) {
      setShowProfileMenu(false);
    }
  });

  const renderProfileImage = () => {
    const userName: string = user?.givenName ?? "user";
    return (
      <div className="flex space-x-2">
        <span className="material-icons pl-1">account_circle</span>
        <div className="hidden md:block">
          <span className="text-sm font-light pt-1">Hello {userName}!</span>
        </div>
      </div>
    );
  };

  const renderUserMenu = () => (
    <div
      className={`absolute cursor-pointer select-none right-[-10px] z-50 mt-2 py-4 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${showProfileMenu ? "" : "hidden"}`}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
    >
      {PermissionService.hasCreatorRole(user) && (
        <a
          href="/content"
          className="block px-4 py-2 text-sm w-full text-blue-800 hover:bg-blue-100 text-center"
          role="menuitem"
          tabIndex={0}
          id="user-menu-item-2"
        >
          Manage Content
        </a>
      )}

      <a
        href="/documents"
        className="block px-4 py-2 text-sm w-full text-blue-800 hover:bg-blue-100 text-center"
        role="menuitem"
        tabIndex={0}
        id="user-menu-item-3"
      >
        Documents
      </a>

      {PermissionService.hasVendorsRole(user) && (
        <a
          href="/vendors"
          className="block px-4 py-2 text-sm w-full text-blue-800 hover:bg-blue-100 text-center"
          role="menuitem"
          tabIndex={0}
          id="user-menu-item-5"
        >
          Vendor Rolodex
        </a>
      )}

      {PermissionService.hasItemsRole(user) && (
        <a
          href="/items"
          className="block px-4 py-2 text-sm w-full text-blue-800 hover:bg-blue-100 text-center"
          role="menuitem"
          tabIndex={0}
          id="user-menu-item-4"
        >
          Item Rolodex
        </a>
      )}

      <button
        type="button"
        onClick={() => AuthApi.logout()}
        className="block px-4 py-2 text-sm w-full text-blue-80 hover:bg-blue-100 text-center"
        role="menuitem"
        tabIndex={0}
        id="user-menu-item-6"
      >
        Sign out
      </button>
    </div>
  );

  const renderProfile = () => (
    <div
      role="presentation"
      onKeyDown={(e) => onProfileSelected(e)}
      onClick={(e) => onProfileSelected(e)}
      className="relative inline-block cursor-pointer select-none"
    >
      {renderProfileImage()}
      {renderUserMenu()}
    </div>
  );

  // temporarily disabled until further notice
  // const renderSearch = () => (
  //   <div className="flex w-full justify-end -mr-3 -mt-0.5 group">
  //     <label className="relative text-gray-500 group-hover:text-gray-700 focus-within:text-gray-600 block">
  //       <span className="material-icons absolute pl-2 py-0.5">search</span>
  //       <input
  //         type="text"
  //         name="search"
  //         id="search-input"
  //         placeholder="Site search"
  //         className="rounded-xl border-2 bg-white placeholder-gray-500 text-gray-600 appearance-none pl-8 focus:outline-none w-2 opacity-0 focus:opacity-100 focus:w-96 transition-all cursor-pointer focus:cursor-text"
  //       />
  //     </label>
  //   </div>
  // );

  const renderNotificationMenu = () => (
    <div
      className={`absolute right-0 z-10 mt-2 p-8 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${showNotificationMenu ? "" : "hidden"}`}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
    >
      <div className="whitespace-nowrap">You have no notifications.</div>
    </div>
  );

  const renderNotifications = () => (
    <button
      type="button"
      onClick={() => setShowNotificationMenu(!showNotificationMenu)}
      className="relative inline-block cursor-pointer select-none"
    >
      <span className="material-icons">notifications</span>
      {renderNotificationMenu()}
    </button>
  );

  const render = () => (
    <header className="flex-none h-14 border-b-2 bg-gray-150 border-gray-300 text-blue-600">
      <div id="header-content" className="flex pt-3 h-8">
        <div id="branding-area" className="pl-4 pr-1">
          <a href="/" className="flex flex-row">
            <img src={svgLogo as string} className="h-8 mx-2" alt="Logo" />
          </a>
        </div>
        <div
          id="user-info"
          className="flex-grow space-x-1 text-right mr-2 sm:mr-8 sm:pt-1 flex justify-end"
        >
          {/* <div className="inline">{renderSearch()}</div> */}
          {shouldRenderAdditionalButtons && renderNotifications()}
          <div className="inline">{renderProfile()}</div>
        </div>
      </div>
    </header>
  );

  return render();
}
