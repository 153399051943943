import { ChangeEvent, ReactElement } from "react";
import PhoneInput from "react-phone-number-input";
import StyledInputProps from "../interface/StyledInputProps";
import errorExclamationSVG from "../../images/svg/error_exclamation.svg";

export default function LabeledInput<T>(props: {
  config: StyledInputProps;
}): ReactElement {
  const inputStyle: string =
    "block mt-0.5 px-2.5 py-2.5 w-full text-base bg-gray-150 text-gray-800 rounded-t border-b-2 border-gray-400 appearance-none peer";
  const focusStyle: string =
    "focus:outline-none focus:ring-0 focus:border-CostcoAction";
  const readOnlyStyle: string = "bg-gray-200 bg-opacity-60";
  const labelStyle: string =
    "text-xs uppercase text-gray-700 pb-2 tracking-widest";
  const labelFocusStyle: string = "";

  const render = () => {
    const { config } = props;
    const isReadOnly = config.readOnly || false;
    const {
      value,
      isRequired,
      isDropdown,
      dropdownOptions,
      maxLength,
      onSelectChange,
      formatError,
    } = config;
    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
      return onSelectChange && onSelectChange(e);
    };
    return (
      <>
        <label
          className={`${labelStyle} ${labelFocusStyle}${
            formatError ? "text-red font-semibold" : ""
          }`}
          htmlFor={config.id}
        >
          {config.label}
        </label>
        {isRequired && (
          <p className="inline-block ml-[5px] pt-[2px] text-red-500 align-[-3px]">
            *
          </p>
        )}
        {!isDropdown && !config.onPhoneChange && (
          <div className="flex items-center space-x-2">
            <input
              id={config.id}
              name={config.name}
              type={config.type}
              value={value ?? ""}
              readOnly={isReadOnly}
              disabled={isReadOnly}
              onChange={config.onChange}
              className={`${inputStyle} ${focusStyle} ${isReadOnly ? readOnlyStyle : ""} ${config.additionalClasses ?? ""}`}
              maxLength={maxLength}
              placeholder={config.placeholder || ""}
            />
            {formatError && (
              <img
                src={errorExclamationSVG as string}
                className="items-center inline-flex justify-center right-3 absolute "
                alt="format error"
              />
            )}
          </div>
        )}
        {!isDropdown && config.onPhoneChange && (
          <PhoneInput
            id={config.id}
            name={config.name}
            type={config.type}
            readOnly={isReadOnly}
            disabled={isReadOnly}
            value={value ?? ""}
            onChange={config.onPhoneChange}
            maxLength={maxLength}
            className={`${inputStyle} ${focusStyle} ${isReadOnly ? readOnlyStyle : ""} ${config.additionalClasses ?? ""} [&>input]:bg-gray-150`}
            placeholder={config.placeholder || " "}
          />
        )}
        {isDropdown && dropdownOptions && (
          <div>
            <select
              className={`${inputStyle} ${focusStyle} ${isReadOnly ? readOnlyStyle : ""} ${config.additionalClasses ?? " "}`}
              value={value ?? ""}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                Select
              </option>
              {dropdownOptions.map((option) => (
                <option key={option as string} value={option as string}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
      </>
    );
  };

  return render();
}
