import { ReactElement } from "react";

const ItemMappingPage = (): ReactElement => {
  const items = [
    { itemId: "123456", description: "Kirkland Signature Yummy Coffee Beans" },
    { itemId: "774774", description: "Coffee Flavored Soap" },
    { itemId: "789101", description: "Orange Super Soaker K Cups" },
    { itemId: "987643", description: "Coffee Chip Cookies" },
    {
      itemId: "988888",
      description: "Not Coffee Coffee Company - Extra Strength Decaf",
    },
    { itemId: "991000", description: "Keurig 4,096 Piece Starter Coffee Kit" },
    { itemId: "992283", description: "Red Coffee Scoop" },
    { itemId: "994944", description: "Recycled Coffee Filters (144 pack)" },
  ];

  const render = () => {
    return (
      <main className="w-full flex-grow bg-white">
        <div id="main-wrapper" className="mt-8 text-center">
          <h1 className="mb-4 mx-auto max-w-xl text-2xl font-semibold text-gray-700">
            Item Commodity Mapping
          </h1>
          <h2 className="mx-auto max-w-2xl text-lg text-gray-700">
            We need to be able to associate your item(s) with specific
            commodities in order to ensure that we’re collecting the appropriate
            information. We’ve included a list of items that are assigned to
            you. Select from the list of items below to complete your
            assessment.
          </h2>

          <div className="max-w-3xl mx-auto mt-10">
            <div className="relative overflow-x-auto rounded-sm border border-gray-100 shadow-md">
              <table className="w-full text-left text-sm text-gray-700">
                <thead className="text-xs uppercase text-gray-700">
                  <tr className="border-b">
                    <th scope="col" className="px-2 py-3">
                      Item Number
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Item Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Primary Commodity
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Secondary Commodity
                    </th>
                    <th scope="col" className="pr-8 py-3 text-right">
                      Contains Palm Oil
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => {
                    return (
                      <tr
                        key={`item-row-${item.itemId}`}
                        className="border-b bg-white"
                      >
                        <th
                          scope="row"
                          className="whitespace-nowrap px-2 py-4 font-medium text-gray-900"
                        >
                          {item.itemId}
                        </th>
                        <td className="px-6 py-4">{item.description}</td>
                        <td className="px-6 py-4 text-center">-</td>
                        <td className="px-6 py-4 text-center">-</td>
                        <td className="px-8 py-3 text-right">
                          <input
                            aria-label="Select item"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex mt-2 max-w-3xl mx-auto">
            <div className="flex items-center -mt-4 ml-4">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="default-checkbox"
                className="ml-2 text-md text-gray-700"
              >
                Hide items with completed assessments
              </label>
            </div>

            <div className="flex-grow">
              <button
                type="button"
                className="bg-CostcoAction hover:bg-CostcoAction-hover text-white py-2 mt-2 mb-6 px-6 rounded float-right"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </main>
    );
  };

  return render();
};

export default ItemMappingPage;
