import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef, ColGroupDef, RowClickedEvent } from "ag-grid-community";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ItemData from "../../../api/interface/cdh/ItemData";
import {
  APVendorItemTableColumns,
  BBAVendorItemTableColumns,
} from "./VendorDetailsPageColumnDefinitions";
import VendorInfo from "../../../api/interface/cdh/VendorInfo";
import ItemServerSideDatasource from "../../../api/GridDataSources/ItemServerSideDataSource";
import DefaultGrid from "../../../components/tables/DefaultGrid";
import SearchBar from "../../../components/common/SearchBar";
import { generateQueryParams } from "../../../util/Helper";
import filterSVG from "../../../images/svg/filter.svg";

interface ItemsTableProps {
  vendor?: VendorInfo;
  itemSelection: (item?: ItemData) => void;
}

const ItemsTable = (props: ItemsTableProps) => {
  const { vendor, itemSelection } = props;
  const [searchParams] = useSearchParams();
  const getCurrentSearch = () => {
    const itemQuery: string = localStorage.getItem("ItemSearchQuery") ?? "";
    const itemArray = itemQuery.match(/q=.*/);
    return itemQuery && itemArray
      ? itemArray[0].split("=")[1]
      : generateQueryParams(searchParams).query;
  };
  const [itemSearchInput, setItemSearchInput] = useState(getCurrentSearch());

  const useAGColumnDefs: (ColDef<ItemData> | ColGroupDef<ItemData>)[] =
    vendor?.vendorType === "AP"
      ? APVendorItemTableColumns
      : BBAVendorItemTableColumns;

  const itemServerSideDatasource = useMemo(() => {
    const dataSource = new ItemServerSideDatasource(vendor);
    dataSource.searchText = itemSearchInput ?? "";
    return dataSource;
  }, [itemSearchInput, vendor]);

  const onItemRowClicked = (event: RowClickedEvent<ItemData>) => {
    const selectedItem = event.data;
    itemSelection(selectedItem);
  };

  return (
    <div>
      <div className="pb-10 flex flex-row">
        <button type="button" className="flex place-content-start w-1/2">
          <div className="self-center justify-items-start bg-blue w-[32px] h-[32px] rounded-full">
            <img
              src={filterSVG as string}
              alt="Filter"
              className="bg-blue inline-block rounded-full w-[24px] h-[24px] mt-[4px]"
            />
          </div>
          <label className="inline-block text-blue px-4 pt-2 font-[16px]]">
            Custom Column View
          </label>
        </button>
        <div className="ml-80 w-1/2">
          <SearchBar
            value={itemSearchInput ?? ""}
            placeholder="Search by Item Number or Description"
            setValue={setItemSearchInput}
            searchType="items"
          />
        </div>
      </div>
      <div className="shadow min-h-120">
        <DefaultGrid<ItemData>
          rowId={(params) => params.data._id ?? ""}
          datasource={itemServerSideDatasource}
          columnDefs={useAGColumnDefs}
          onRowClicked={onItemRowClicked}
          tableType="item"
        />
      </div>
    </div>
  );
};
export default ItemsTable;
