import { ICellRendererParams } from "ag-grid-community";
import emailSVG from "../../../../images/svg/email.svg";

const VAMContactActionCellRenderer = (params: ICellRendererParams) => {
  const { value } = params;
  const linkString = `mailto:${value}`;

  return (
    <div className="flex flex-row">
      <div className="font-xs flex-item">
        <a href={linkString} target="_blank" rel="noreferrer">
          <img
            className="size-5 mt-2 justify-between"
            src={emailSVG as string}
            alt="Email Icon"
          />
        </a>
      </div>
    </div>
  );
};

export default VAMContactActionCellRenderer;
