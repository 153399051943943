import { ReactElement, useEffect, useState } from "react";

import Axios, { HttpStatusCode } from "axios";
import AuthApi from "./api/AuthApi";
import useAuth from "./hooks/useAuth";
import LoadingPage from "./pages/LoadingPage";
import ErrorPage from "./pages/ErrorPage";
import Router from "./Router";
import MaintenancePage from "./pages/MaintenancePage";

enum ConnectionStatus {
  CONNECTING,
  CONNECTED,
  FAILED,
  MAINTENANCE,
}

export default function App(): ReactElement {
  const { user, setUser } = useAuth();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
    ConnectionStatus.CONNECTING,
  );

  useEffect(() => {
    window.addEventListener("pageshow", (event) => {
      if (event.persisted && sessionStorage.length === 0) {
        window.location.reload();
      }
    });
  }, []);

  const attemptLogin = () => {
    if (!user) {
      AuthApi.getUserInfo().then((userInfo) => {
        if (userInfo?.active) {
          setUser(userInfo);
        } else {
          AuthApi.authorize().then();
        }
      });
    } else {
      // use refresh token to validate that the login is still valid.
    }
  };

  const attemptConnection = () => {
    Axios.get("/healthcheck")
      .then(() => {
        setConnectionStatus(ConnectionStatus.CONNECTED);
      })
      .catch((e) => {
        // Workaround for APIM requirement of Auth on all endpoints
        if (e.response?.status === HttpStatusCode.Unauthorized) {
          setConnectionStatus(ConnectionStatus.CONNECTED);
        } else {
          setConnectionStatus(ConnectionStatus.FAILED);
        }
      });
  };

  const render = () => {
    if (connectionStatus === ConnectionStatus.MAINTENANCE) {
      return <MaintenancePage />;
    }

    if (connectionStatus === ConnectionStatus.FAILED) {
      sessionStorage.setItem("errorTitle", "Unable to connect to backend.");
      sessionStorage.setItem(
        "errorMessage",
        "Please check your internet connection and ensure you are connected to the Costco VPN. " +
          "If problems persist, it may indicate a server configuration issue.",
      );
    }

    const title = sessionStorage.getItem("errorTitle");
    const message = sessionStorage.getItem("errorMessage");
    const details = sessionStorage.getItem("errorDetails");

    if (title && message) {
      sessionStorage.clear();
      return (
        <ErrorPage
          title={title}
          message={message}
          details={details ?? undefined}
        />
      );
    }

    if (connectionStatus === ConnectionStatus.CONNECTING) {
      attemptConnection();
      return <LoadingPage />;
    }

    if (!user) {
      attemptLogin();
      return <LoadingPage />;
    }

    return <Router />;
  };

  return render();
}
