import { RowClickedEvent } from "ag-grid-community";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import VendorInfo from "../../../api/interface/cdh/VendorInfo";
import DefaultGrid from "../../../components/tables/DefaultGrid";
import VendorChildrenServerSideDatasource from "../../../api/GridDataSources/VendorChildrenServerSideDataSource";
import { DefaultChildVendorColumns } from "./VendorDetailsPageColumnDefinitions";
import SearchBar from "../../../components/common/SearchBar";
import { generateQueryParams } from "../../../util/Helper";

interface BBATableProps {
  vendor?: VendorInfo;
  selectVendor: (vendor: VendorInfo) => void;
}

const BBATable = (props: BBATableProps) => {
  const { vendor, selectVendor } = props;
  const [searchParams] = useSearchParams();
  const getCurrentSearch = () => {
    const childQuery: string = localStorage.getItem("ChildSearchQuery") || "";
    const childArray = childQuery.match(/q=.*/);
    return childArray
      ? childArray[0].split("=")[1]
      : generateQueryParams(searchParams).query;
  };
  const [bbaSearchInput, setBbaSearchInput] = useState(getCurrentSearch());
  const onChildVendorRowSelected = (event: RowClickedEvent<VendorInfo>) => {
    const childVendorSelected = event.data;
    if (childVendorSelected) {
      selectVendor(childVendorSelected);
    }
  };
  const vendorChildServerSideDatasource = useMemo(() => {
    const dataSource = new VendorChildrenServerSideDatasource(vendor!);
    dataSource.searchText = bbaSearchInput ?? "";
    return dataSource;
  }, [bbaSearchInput, vendor]);

  return (
    <div>
      <div className="pb-10 pl-96">
        <SearchBar
          value={bbaSearchInput ?? ""}
          placeholder="Search by BBA Number"
          setValue={setBbaSearchInput}
          searchType="bba"
        />
      </div>
      <div className="shadow min-h-120">
        <DefaultGrid<VendorInfo>
          rowId={(params) => params.data._id ?? ""}
          datasource={vendorChildServerSideDatasource}
          columnDefs={DefaultChildVendorColumns}
          onRowClicked={onChildVendorRowSelected}
          tableType="child vendor"
        />
      </div>
    </div>
  );
};

export default BBATable;
