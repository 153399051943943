import { useParams } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import CostcoBlueLoader from "../components/common/LoadingIndicators";
import FileApi from "../api/FileApi";
import AppSettingsService from "../api/AppSettingsService";

export default function FilePage() {
  const splat = useParams()["*"];
  // Use the Moonloader as the default image
  const [content, setContent] = useState<ReactElement>(CostcoBlueLoader);

  const loadURL = new URL(
    `/file/static/${splat}`,
    AppSettingsService.getBaseUrl(),
  ).toString();

  useEffect(() => {
    FileApi.getECMContentFromURL(loadURL).then((contentURL) => {
      setContent(
        <iframe src={contentURL} className="h-screen w-screen" title={splat} />,
      );
    });
  }, [loadURL, splat]);

  const render = () => {
    return <div className="h-screen w-screen">{content}</div>;
  };

  return render();
}
