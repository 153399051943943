import { ReactElement, useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import PageContent from "../api/interface/PageContent";
import ContentApi from "../api/ContentApi";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import ContentTable from "../components/content/ContentTable";
import QueryParams from "../api/interface/QueryParams";
import { DocumentTableColumns } from "../components/content/ContentTableColumns";

const DocumentsPage = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [items, setItems] = useState<PageContent[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const queryParams: QueryParams = {
    itemsPerPage: parseInt(searchParams.get("s") ?? "5", 10),
    pageNumber: parseInt(searchParams.get("p") ?? "1", 10),
    sortBy: searchParams.get("sortBy") ?? "title",
    contentFilter: "documents",
    statusFilter: "live",
  };

  /** Checks for any pending messages to display toast messages. */
  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  /** Queries the API for page data, when sort, pagination, or filter params change. */
  useEffect(() => {
    const qp = {
      itemsPerPage: parseInt(searchParams.get("s") ?? "10", 10),
      pageNumber: parseInt(searchParams.get("p") ?? "1", 10),
      sortBy: searchParams.get("sortBy") ?? "title",
      sortDescending: searchParams.has("sortDescending"),
      contentFilter: "documents",
      statusFilter: "published",
    };
    ContentApi.getAll(qp).then((paginatedResponse) => {
      setItems(paginatedResponse.items);
      setTotalItems(paginatedResponse.size);
    });
  }, [searchParams]);

  const getSearchParams = () => {
    const search: string[] = [];
    // Add pagination
    search.push(`p=${queryParams.pageNumber}`);
    search.push(`s=${queryParams.itemsPerPage}`);
    // Add sort options
    search.push(`sortBy=${queryParams.sortBy}`);
    return search;
  };

  const updateSearchParams = () => {
    const search = getSearchParams().join("&");
    if (search !== location.search) {
      navigate({
        pathname: `/documents`,
        search,
      });
    }
  };

  const updatePagination = (pageNumber: number, itemsPerPage: number) => {
    if (queryParams.itemsPerPage !== itemsPerPage) {
      pageNumber = 1;
    }
    queryParams.pageNumber = pageNumber;
    queryParams.itemsPerPage = itemsPerPage;
    updateSearchParams();
  };

  const updateSort = (sortBy: string) => {
    if (queryParams.sortBy === sortBy) {
      queryParams.sortBy = `-${sortBy}`;
    } else {
      queryParams.sortBy = sortBy;
    }
    updateSearchParams();
  };

  /** Navigates to a page for creating/viewing/modifying a PageContent item. */
  const viewContent = (pageContent: PageContent | undefined) => {
    const target = pageContent?.link;
    if (target) {
      // TODO(awbaumann): [NOW] Initiate file download.
    }
  };

  const render = () => (
    <div className="min-h-screen flex flex-col">
      <div className="sticky top-0 z-10">
        <Header />
      </div>
      <main className="w-full flex-grow flex-1 flex flex-col wf bg-blue-slate/10">
        <div className="flex-row flex-grow self-center w-full box-border relative">
          <div
            id="main-wrapper"
            className="px-8 py-4 w-full text-left relative"
          >
            <div className="overflow-x-auto w-full md:p-4 min-w-96">
              <ContentTable
                items={items}
                totalItems={totalItems}
                selectItem={viewContent}
                queryParams={queryParams}
                pageFunction={updatePagination}
                sortFunction={updateSort}
                columns={DocumentTableColumns}
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ToastContainer position="bottom-right" />
    </div>
  );

  return render();
};

export default DocumentsPage;
