
import UserInfo from "../api/interface/oauth/UserInfo";
import Role from "./interface/Role";

export default class PermissionService {
  private static MEMBER_OF_MAP = new Map<string, Role>([
    ["CN=!All_UsersCorporateUS", Role.COSTCO_USER],
    ["CN=!App-Sportal-ContentMgmt-Creator", Role.COSTCO_CONTENTMGMT_CREATOR],
    ["CN=!App-Sportal-ContentMgmt-Admin", Role.COSTCO_CONTENTMGMT_ADMIN],
    ["CN=!App-Sportal-VendorRolodex-ReadOnly", Role.COSTCO_VENDORROLODEX_READONLY],
    ["CN=!App-Sportal-ItemRolodex-ReadOnly", Role.COSTCO_ITEMROLODEX_READONLY],
    ["CN=!App-Sportal-VendorProfile-ReadOnly", Role.COSTCO_VENDORPROFILE_READONLY],
    ["CN=!App-Sportal-VendorProfile-Admin", Role.COSTCO_VENDORPROFILE_ADMIN],
    ["CN=!App-Sus-Portal", Role.VENDOR_SUSTAINABILITY_USER],
  ]);

  private static VENDOR_ROLES_MAP = new Map<string, Role>([
    ["VSP-ESG_Lead", Role.VENDOR_ESG_LEAD],
    ["EAM-VendorAdmin", Role.VENDOR_ADMIN]
  ])

  public static getUserPermissions(userInfo: UserInfo | undefined) {
    const roles = new Set<Role>();
    if (userInfo === undefined) {
      return roles;
    }

    const memberOfArray: string[] = userInfo.memberOf ?? [];
    memberOfArray.forEach((membership) => {
      // Catches anything in the pattern of CN= to get the group name.
      if (membership.startsWith("CN=")) {
        membership = membership.split(",")[0]!;
        const role = PermissionService.MEMBER_OF_MAP.get(membership);
        if (role) {
          roles.add(role);
        }
      }
    });

    const vendorAppRoles: string[] = userInfo["costco-vendor-approles"] ?? [];
    vendorAppRoles.forEach((appRole) => {
      const roleName = appRole.split(",")[0]!;
      const role = PermissionService.VENDOR_ROLES_MAP.get(roleName);
      if (role) {
        roles.add(role);
      }
    })

    return roles;
  }

  private static checkPermissions(
    userLevels: Set<Role>,
    ...levels: Role[]
  ): boolean {
    return levels.some((level) => userLevels.has(level));
  }

  static hasPermissionLevel(user?: UserInfo, ...levels: Role[]) {
    if (user === undefined) return false;
    return PermissionService.checkPermissions(
      PermissionService.getUserPermissions(user),
      ...levels,
    );
  }

  static getVendorSaps(user?: UserInfo, ...levels: Role[]) {
    if (user === undefined) return false;
    return PermissionService.checkPermissions(
      PermissionService.getUserPermissions(user),
      ...levels,
    );
  }

  static hasApproverRole(user?: UserInfo) {
    return PermissionService.hasPermissionLevel(
      user,
      Role.COSTCO_CONTENTMGMT_ADMIN,
    );
  }

  static hasCreatorRole(user?: UserInfo) {
    return PermissionService.hasPermissionLevel(
      user,
      Role.COSTCO_CONTENTMGMT_ADMIN,
      Role.COSTCO_CONTENTMGMT_CREATOR,
    );
  }

  static hasVendorsRole(user?: UserInfo) {
    return PermissionService.hasPermissionLevel(
      user,
      Role.COSTCO_VENDORPROFILE_ADMIN,
      Role.COSTCO_VENDORPROFILE_READONLY,
      Role.COSTCO_VENDORROLODEX_READONLY,
      Role.VENDOR_ADMIN,
      Role.VENDOR_ESG_LEAD
    );
  }

  static hasItemsRole(user?: UserInfo) {
    return PermissionService.hasPermissionLevel(
      user,
      Role.COSTCO_ITEMROLODEX_READONLY,
      Role.VENDOR_ADMIN,
      Role.VENDOR_ESG_LEAD
    );
  }
}
