import Axios from "axios";

import VendorInfo from "./interface/cdh/VendorInfo";
import Contact from "./interface/Contact";
import ContentMessageStatus from "./interface/ContentMessageStatus";


export type ContactMessage = {
  contact?: Contact;
  message: string;
  status: ContentMessageStatus;
};

export default class VendorApi {
  public static async getVendor(
    sapNumber: string,
    bbaNumber?: string
  ): Promise<VendorInfo | undefined> {

    try {
      const response = await Axios.get(`/vendors/${sapNumber}/${bbaNumber ?? ''}`);
      return response.data;
    } catch (_exception) {
      return undefined;
    }
  }

  public static async createContact(contact: Contact): Promise<ContactMessage> {
    try {
      const response = await Axios.post("/contacts", contact);
      return {
        contact: response.data,
        message: "Contact created",
        status: ContentMessageStatus.SUCCESS,
      };
    } catch (exception) {
      throw new Error('Failed to create contact.', { cause: exception });
    }
  }

  public static async updateContact(contact: Contact): Promise<ContactMessage> {
    try {
      let response = await Axios.put("/contacts", contact);
      return {
        contact: response.data,
        message: "Contact updated",
        status: ContentMessageStatus.SUCCESS,
      };
    } catch (exception) {
      throw new Error('Failed to update contact.', { cause: exception });
    }
  }

  public static async deleteContact(contact: Contact) : Promise<ContactMessage> {
    contact.sustainabilityRole = "DELETED";
    try {
      let response = await Axios.put("/contacts", contact);
      return {
        contact: response.data,
        message: "Contact Deleted",
        status: ContentMessageStatus.SUCCESS,
      };
    } catch (exception) {
      throw new Error('Failed to delete contact.', { cause: exception });
    }
  }

}
