import { ReactNode, MouseEvent, ReactElement } from "react";
import { Link } from "react-router-dom";
import { isFirefox } from "react-device-detect";
import mime from "mime";
import FileApi from "../../api/FileApi";

/**
 * Loads an image, if the image is from ECM, this will request it in a secure context.
 * While loading, it will show as a costco blue loading spinner
 * @param props props with image URL
 * @constructor
 */
export default function ECMContentLink(props: {
  id?: string;
  contentUrl?: string;
  className?: string;
  children?: ReactNode;
}): ReactElement {
  const handleLinkClick = async (e: MouseEvent) => {
    e.preventDefault();
    if (props.contentUrl) {
      FileApi.getECMContentFromURL(props.contentUrl).then((fileUrl) => {
        const fileNameStart = props.contentUrl?.lastIndexOf("/");
        if (fileNameStart && fileNameStart >= 0) {
          const fileName = props.contentUrl?.substring(fileNameStart + 1);
          const mimeType = mime.getType(fileName!);
          if (
            fileName &&
            !isFirefox &&
            // If the mimetype is text, then it can be displayed in the browser
            !mimeType?.startsWith("text/")
          ) {
            // Create a link element
            const link = document.createElement("a");

            // Set link's href to point to the Blob URL
            link.href = fileUrl;
            link.target = "_blank";
            link.download = fileName;

            // Append link to the body
            document.body.appendChild(link);

            link.click();

            // Remove link from body
            document.body.removeChild(link);
          } else {
            window.open(fileUrl, "_blank");
          }
        } else {
          window.open(fileUrl, "_blank");
        }
      });
    }
  };

  const render = () => {
    return (
      // Disabling Eslint error so the browser doesn't treat the operation as a popup to block
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        to="#"
        type="button"
        target="_blank"
        className={props.className}
        onClick={handleLinkClick}
      >
        {props.children}
      </Link>
    );
  };

  return render();
}
