import { ReactElement } from "react";
import {ColDef, ColGroupDef, ValueFormatterParams} from "ag-grid-community";
import VendorInfo from "../../api/interface/cdh/VendorInfo";

export default interface ColumnDefinition<Type> {
  name: string;
  hideHeaderText?: boolean;
  sortable?: boolean;
  sortValue?: string;
  styles?: string;
  value: (data: Type) => string | ReactElement;
}

const countryFormatter = (params: ValueFormatterParams) => {
  if (!params.value) {
    return "";
  }
  switch (params.value) {
    case 1:
      return "US";
    case 4:
      return "CN";

    default:
      return "Others";
  }
};
const vendorIDColDefs: ColDef<VendorInfo>[] = [
  {
    headerName: "Country / Region",
    field: "company",
    filter: false,
    valueFormatter: countryFormatter,
    flex: 1,
  },
  {
    headerName: "AP Number",
    field: "apNumber",
    filter: false,
    flex: 1,
  },
  {
    headerName: "AP Suffix",
    field: "apSuffix",
    filter: false,
    flex: 1,
  },
  {
    headerName: "SAP Number",
    field: "sapNumber",
    filter: false,
    flex: 1,
  },
]

const vendorNameColDef: ColDef<VendorInfo> = {
    field: "name",
    filter: false,
    flex: 2,
};

// Column Definitions: Defines the columns to be displayed.
export const defaultColumnHeaders: (ColDef<VendorInfo> | ColGroupDef<VendorInfo>)[] = [
  ...vendorIDColDefs,
  {
    headerName: "BBA Number",
    field: "bbaNumber",
    filter: false,
    flex: 1,
  },
  vendorNameColDef
];

export const apVendorsColumnHeaders: (ColDef<VendorInfo> | ColGroupDef<VendorInfo>)[]  = [
  ...vendorIDColDefs,
  vendorNameColDef
];
