import { ReactElement } from "react";
import PageContent from "../../api/interface/PageContent";
import FileIconUtil from "../../util/FileIconUtil";
import ECMContentLink from "../common/ECMContentLink";

export default function DocumentContent(props: {
  item: PageContent;
}): ReactElement {
  const getFileIcon = (item: PageContent) => {
    const link = item.link || "";
    const lastDot = link?.lastIndexOf(".") || -1;
    const extension = link?.substring(lastDot + 1).toLowerCase();
    return <FileIconUtil extension={extension} />;
  };

  const render = () => {
    const { item } = props;
    const content = (
      <div className="first:border-t-0 border-y flex flex-row p-2">
        <div className="flex-none -mt-0.5 ml-1 mr-4 h-8 w-8">
          {getFileIcon(item)}
        </div>
        <div className="-mt-0.5">
          <div className="text-base font-semibold">{item.title}</div>
          <div className="text-sm break-normal">{item.subtitle}</div>
        </div>
      </div>
    );

    if (item.link) {
      return <ECMContentLink contentUrl={item.link}>{content}</ECMContentLink>;
    }
    return content;
  };

  return render();
}
