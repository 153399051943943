import svgLoading from "../../images/svg/loading.svg";

const CostcoBlueLoader = () => (
  <div className="flex h-full w-full place-items-center place-content-center">
    <div className="mx-auto mt-80 object-center w-16 h-16">
      <img src={svgLoading} alt="loading..." />
    </div>
  </div>
);

export default CostcoBlueLoader;
