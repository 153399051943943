import { ReactElement, useEffect, useMemo, useState } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { toast, ToastContainer } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { RowClickedEvent } from "ag-grid-community";
import { Tooltip } from "react-tooltip";
import { generateQueryParams } from "../util/Helper";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import QueryParams from "../api/interface/QueryParams";
import DefaultGrid from "../components/tables/DefaultGrid";
import ItemServerSideDatasource from "../api/GridDataSources/ItemServerSideDataSource";
import ItemData from "../api/interface/cdh/ItemData";
import SearchBar from "../components/common/SearchBar";
import { BBAVendorItemTableColumns } from "./VendorDetailsPage/VendorDetailsProperties/VendorDetailsPageColumnDefinitions";
import ItemDetailsSection from "../components/sections/ItemDetailsSection";
import svgExclamation from "../images/svg/exclamation.svg";

export default function VendorSearchPage(): ReactElement {
  const [searchParams] = useSearchParams();
  const queryParams: QueryParams = useMemo(
    () => generateQueryParams(searchParams),
    [searchParams],
  );

  const [showItemDetails, setShowItemDetails] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemData>();

  const itemServerSideDatasource = useMemo(() => {
    const dataSource = new ItemServerSideDatasource();
    dataSource.searchText = queryParams.query ?? "";
    return dataSource;
  }, [queryParams]);

  /** Checks for any pending messages to display toast messages. */
  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  const renderSearch = () => {
    return (
      <div className="w-full shrink pl-8 pr-4 lg:min-w-[996px] md:min-w-[650px] sm:min-w-[500px] xs:min-w-[400px] mt-4">
        <div className="flex pb-4">
          <div className="block font-medium text-lg">Search By</div>
          <img
            id="item-rolodex-search-by-tooltip"
            src={svgExclamation as string}
            className="inline-block pl-4 text-gray-dark"
            alt="exclamation icon"
          />
          <Tooltip
            anchorSelect="#item-rolodex-search-by-tooltip"
            className="text-sm"
            place="right"
          >
            Search by Item Number or Description
          </Tooltip>
        </div>
        <SearchBar
          value={queryParams.query ?? ""}
          setValue={() => {}}
          searchType="itemSearchPage"
          placeholder="Search by Item Number or Description"
        />
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
        />
      </div>
    );
  };

  const renderActionBar = () => {
    return (
      <div
        id="actionBar"
        className="flex flex-col py-4 flex-none border-b bg-white shadow sticky z-10 top-12"
      >
        <div className="flex ml-8 mb-3 min-w-full">
          <p className="text-3xl no-wrap">Item Rolodex</p>
        </div>
        <div className="flex">{renderSearch()}</div>
      </div>
    );
  };

  const itemSelection = (item?: ItemData) => {
    setSelectedItem(item);
    setShowItemDetails(item !== undefined);
  };

  const onItemRowClicked = (event: RowClickedEvent<ItemData>) => {
    const itemClicked = event.data;
    itemSelection(itemClicked);
  };

  const renderGrid = () => {
    return (
      <DefaultGrid<ItemData>
        rowId={(params) => params.data._id ?? ""}
        datasource={itemServerSideDatasource}
        columnDefs={BBAVendorItemTableColumns}
        onRowClicked={onItemRowClicked}
        tableType="item"
      />
    );
  };

  const onItemClose = () => {
    setShowItemDetails(false);
  };

  const render = () => (
    <div className="min-h-screen flex flex-col">
      <div className="sticky top-0 z-10">
        <Header />
        {renderActionBar()}
      </div>
      <main className=" flex-grow flex-1 flex flex-col w-full wf bg-blue-slate/10">
        <div className=" flex-grow self-center flex w-full box-border relative">
          <div
            className="ag-theme-quartz overflow-x-auto w-full h-full md:p-4 md:h-auto flex-grow" // applying the Data Grid theme
          >
            {renderGrid()}
          </div>
        </div>
        <div
          id="main-wrapper"
          className="px-8 py-4 w-full text-left relative"
        />
      </main>
      <Footer />
      <ToastContainer position="bottom-right" />
      <ItemDetailsSection
        visible={showItemDetails}
        item={selectedItem}
        closeFunction={onItemClose}
      />
    </div>
  );
  return render();
}
