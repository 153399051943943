import { ReactElement } from "react";
import ColumnDefinition from "./ColumnDefinition";

type DataTableRowProps<Type> = {
  id: string;
  columns: Array<ColumnDefinition<Type>>;
  data: Type;
  onClick: (data: Type) => void;
};

const DataTableRow = <Type,>(props: DataTableRowProps<Type>): ReactElement => {
  const DEFAULT_STYLES =
    "px-4 block antialiased font-sans text-sm text-nowrap leading-normal font-normal text-center border-l border-blue-900/40";

  const render = () => {
    const { id, columns, data, onClick } = props;
    const cells: ReactElement[] = [];
    columns.forEach((column) => {
      const rowId = `data-row-${id}-${column.name}`;
      if (column.styles) {
        cells.push(
          <td key={rowId} className={column.styles as string}>
            {column.value(data)}
          </td>,
        );
      } else {
        cells.push(
          <td key={rowId} className="py-4">
            <p className={DEFAULT_STYLES}>&nbsp;{column.value(data)}&nbsp;</p>
          </td>,
        );
      }
    });
    return (
      <tr
        className="hover:bg-blue-slate/20 border-b border-blue-gray-50"
        onClick={() => onClick(data)}
      >
        {cells.map((cell) => cell)}
      </tr>
    );
  };

  return render();
};

export default DataTableRow;
