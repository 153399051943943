import { ICellRendererParams } from "ag-grid-community";
import { MouseEventHandler } from "react";
import emailSVG from "../../../../images/svg/email.svg";
import editSVG from "../../../../images/svg/edit.svg";
import deleteSVG from "../../../../images/svg/delete.svg";

interface ContactActionRendererProps extends ICellRendererParams {
  onEditClick: (event: MouseEventHandler) => void;
  onDeleteClick: (event: MouseEventHandler) => void;
}

const ContactActionCellRenderer = (params: ContactActionRendererProps) => {
  const { data, value, onEditClick, onDeleteClick } = params;
  const linkString = `mailto:${value}`;
  const handleEdit = () => {
    onEditClick(data);
  };
  const handleDelete = () => {
    onDeleteClick(data);
  };
  return (
    <div className="flex flex-row">
      <div className="font-xs flex-item">
        <a href={linkString} target="_blank" rel="noreferrer">
          <img
            className="size-5 mt-2 mx-1"
            src={emailSVG as string}
            alt="Email Icon"
          />
        </a>
      </div>
      <div className="flex-item">
        <button type="button" onClick={handleEdit}>
          <img
            className="size-5 mt-2 mx-1"
            src={editSVG as string}
            alt="Edit Icon"
          />
        </button>
      </div>
      <div className="flex-item">
        <button type="button" onClick={handleDelete}>
          <img
            className="size-5 mt-2 mx-1"
            src={deleteSVG as string}
            alt="Delete Icon"
          />
          Delete
        </button>
      </div>
    </div>
  );
};

export default ContactActionCellRenderer;
