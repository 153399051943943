import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Contact from "../../../api/interface/Contact";
import { DefaultContactsTableColumns } from "./VendorDetailsPageColumnDefinitions";
import VendorInfo from "../../../api/interface/cdh/VendorInfo";
import ContactServerSideDatasource from "../../../api/GridDataSources/ContactServerSideDataSource";
import DefaultGrid from "../../../components/tables/DefaultGrid";
import ContactActionCellRenderer from "./CellRenderer/ContactActionCellRenderer";
import VendorApi from "../../../api/VendorApi";
import { useConfirmationModalContext } from "../../../context/ModalContext";
import svgExclamation from "../../../images/svg/exclamation.svg";

interface ContactsTableProps {
  vendor?: VendorInfo;
  contactSelection: (contact?: Contact) => void;
}

const ContactsTable = (props: ContactsTableProps) => {
  const { vendor, contactSelection } = props;
  const [working, setWorking] = useState<boolean>(false);
  const navigate = useNavigate();
  const modalContext = useConfirmationModalContext();
  const contactServerSideDatasource = useMemo(
    () => new ContactServerSideDatasource(vendor!.sapNumber),
    [vendor],
  );

  const onNewContact = () => {
    contactSelection(undefined);
  };

  const onDeleteContact = async (data: Contact) => {
    if (working) return false;
    setWorking(true);
    const confirm = await modalContext.showConfirmation(
      "Delete contact",
      <div>Are you sure you want to delete this contact?</div>,
      "Delete",
    );
    if (!confirm) {
      setWorking(false);
      return false;
    }
    await VendorApi.deleteContact(data).then(() => {
      localStorage.setItem("successMessage", "Contact deleted");
    });
    setWorking(false);
    navigate(0);
    return true;
  };

  const timePattern = (params: ValueFormatterParams) => {
    // This pattern should be updated with different regional requirements
    if (!params.value) {
      return "";
    }
    const date = new Date(params.value);
    return `${date.toLocaleDateString("en-us", { timeZone: "UTC" })}`;
  };
  const ContactsTableColumnsWithActions: (
    | ColDef<Contact>
    | ColGroupDef<Contact>
  )[] = DefaultContactsTableColumns.concat([
    {
      headerName: "Sustainability Role",
      sortable: true,
      field: "sustainabilityRole",
      flex: 1.5,
      headerClass: "contactTableHeader",
    },
    {
      headerName: "Last Updated (UTC)",
      sortable: false,
      field: "updatedAt" || "createdAt",
      flex: 1.5,
      valueFormatter: timePattern,
      headerClass: "contactTableHeader",
      cellClass: "text-sm",
    },
    {
      headerName: "Action",
      flex: 1,
      sortable: false,
      headerClass: "contactTableHeader",
      field: "email",
      cellRenderer: ContactActionCellRenderer,
      cellRendererParams: {
        onEditClick: (data: Contact) => {
          contactSelection(data);
        },
        onDeleteClick: (data: Contact) => {
          onDeleteContact(data);
        },
      },
    },
  ]);

  const classes: string =
    "rounded border-gray-300 relative border-s-gray-50 px-4 py-auto border text-sm hover:bg-gray-300 hover:text-black bg-CostcoAction text-white border-CostcoAction justify-end";
  return vendor ? (
    <div className="flex flex-col mt-8 mb-8">
      <div className="flex w-full h-10 mb-4 justify-between">
        <div className="flex items-center">
          <div className="text-lg font-black no-wrap">
            Sustainability Contacts
          </div>
          <img
            id="vendor-profile-not-registered-portal-users-tooltip"
            src={svgExclamation as string}
            className="inline-block pl-4 text-gray-dark"
            alt="exclamation icon"
          />
          <Tooltip
            anchorSelect="#vendor-profile-not-registered-portal-users-tooltip"
            place="right"
            className="text-sm"
          >
            These users are not registered to access the Vendor Sustainability
            Portal
          </Tooltip>
        </div>
        <button className={classes} type="button" onClick={onNewContact}>
          Add Contact
        </button>
      </div>
      <DefaultGrid<Contact>
        rowId={(params) => params.data._id ?? ""}
        datasource={contactServerSideDatasource}
        columnDefs={ContactsTableColumnsWithActions}
        tableType="contact"
      />
    </div>
  ) : (
    <div className="snap-center flex font-normal h-96">
      <p>No contacts found.</p>
    </div>
  );
};
export default ContactsTable;
